import React from 'react'

import styled from 'styled-components'
import colors from 'src/assets/styles/colors'
import mediaQuery from 'src/assets/styles/mediaQuery'
import typographySizes from 'src/assets/styles/typographySizes'
import MainWrapper from 'src/components/global/MainWrapper.js'
import { useState, useEffect } from 'react'

import Seo from 'src/components/global/Seo.js'

import TitleH1 from 'src/components/global/texts/TitleH1.js'
import { Helmet } from 'react-helmet'

const StyledMain = styled.main`
  background-color: ${colors.mainColor};
  width: 100vw;
  position: relative;
  padding-top: 70rem;
  ${MainWrapper} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: 125rem;
  }
  ${TitleH1} {
    width: 80vw;
    max-width: 900rem;
    text-align: center;
    z-index: 10;
    font-size: ${typographySizes.m}rem;
    @media (max-width: ${mediaQuery.tablet}) {
      font-size: ${typographySizes.m}rem;
      width: calc(100% - 20rem);
    }
    a {
      font-size: inherit;
      color: inherit;
      :hover {
        color: ${colors.mainColor};
      }
    }
  }
`

//hide scrollbar in iframe
const StyledIframe = styled.iframe`
  width: 100%;
  border: 0px;
  outline: 0px;
  height: calc(100vh - 70rem);
  &::webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

const ConfirmationPage = () => {
  const [paymentId, setPaymentId] = useState(null)
  const [paymentStatus, setPaymentStatus] = useState(null)

  useEffect(() => {
    let params = new URL(document.location).searchParams
    setPaymentId(params.get('paymentId'))
    setPaymentStatus(params.get('paymentStatus'))
  }, [])

  if (paymentId === null || paymentStatus === null) {
    return (
      <StyledMain>
        <MainWrapper>
          <TitleH1>Trwa przekierowywanie...</TitleH1>
        </MainWrapper>
      </StyledMain>
    )
  }

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Helmet>
      <Seo
        title="KONSULTACJE"
        description="Umów konsultację z realizatorem NOBOCOTO"
      />
      <StyledMain>
        {/* <FrameWrapper /> */}
        {/* <TextBarAnimation>umów konsultację</TextBarAnimation> */}

        <StyledIframe
          id="konsultacje"
          src={`https://nbct-forms.vercel.app/konsultacje/potwierdzenie?paymentId=${paymentId}&paymentStatus=${paymentStatus}`}
          width="100%"
        >
          {' '}
        </StyledIframe>
      </StyledMain>
    </>
  )
}

export default ConfirmationPage
